.mobileButtonCondensed {
  width: unset;
}

/* About Navbar */
.tabLinkButton {
  display: inline-flex;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  text-decoration: none;
}

/* Treet Protection */
.treetProtection {
  & ul {
    list-style-type: disc;
    margin-left: 24px;
  }

  & ol,
  & ul {
    padding-inline-start: unset;
    list-style-position: inside;
    margin: unset;
  }
}

/* About Pages Wrapper */
.icon {
  height: 24px;
  max-width: 90%;
  width: auto;
  filter: brightness(0) invert(1);
}

.footerAdditionMobile {
  margin: 4px 0;
}

.topbarAboveHero {
  /* override positioning of topbar container to be absolutely on top. */
  position: fixed;
}
